.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 40px 30px; /* Adjust padding for decreased height */
  margin: 0; /* Remove margin */
  height: 30px; /* Adjust height */
}

.header-title-main {
  padding-left: 100px; /* Move the header text slightly to the right */
  font-family: 'Batang';
  font-weight: bolder;
  font-size: 30px; 
}

.header-title-main h1 {
  margin: 50; /* Remove margin for the h1 element */
  font-size: 27px; /* Increase font size for better visibility */
  font-family: 'Arial Greek';
  font-weight: lighter;
}

.header-sub-title {
  padding-left: 100px; /* Move the header text slightly to the right */
  font-family: 'Calibri';
  font-size: 20px; 
}

.header-sub-title h1 {
  /* margin: 50; Remove margin for the h1 element */
  font-size: 20px; /* Increase font size for better visibility */
  font-family: 'Calibri';
  font-weight: lighter;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #000;
  color: #fff;
  display: none;
}

.menu.show {
  display: block;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Add flex-wrap to wrap menu items */
}

.menu ul li {
  margin-right: 20px;
  margin-bottom: 10px; /* Add margin-bottom for spacing between lines */
  cursor: pointer;
}

.menu-toggle {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.photo-section {
  margin-top: 0; /* Remove margin-top */
  position: relative;
  height:10%
}

.photo-container {
  position: relative;
  overflow: hidden;
}

.slide {
  display: none;
}

.slide.active {
  display: block;
}

.slide-image {
  max-width: 100%;
  height: fit-content;
}

.circles {
  position: absolute;
  bottom: 30px; /* Adjust vertical position as needed */
  width: 100%; /* Ensure full width */
  display: flex;
  justify-content: center; /* Center circles horizontally */
}

.circles span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
  cursor: pointer;
}

.circles span.overlay-active {
  background-color: black;
}
