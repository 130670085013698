/* OverlaySection.css */

.overlay-slider-section {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(54, 47, 47); /* Semi-transparent gray background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-slider-container {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 80%;
  height: 80%;
  padding: 100px; /* Adjust padding as needed */
}

.overlay-slide {
  position: relative;
  display: none;
  text-align: center;
  width: 50%;
  height: 100%;
}

.overlay-slide.overlay-active {
  display: flex;
}

.overlay-slide-image {
  width: 100%;
  height: auto;
}

.overlay-overlay-box {
  position: absolute;
  top: 50%; /* Center vertically */
  left: calc(73% + 25%); /* Attach to the right side of the picture */
  transform: translateY(-50%); /* Center vertically */
  width: 70%; /* Adjust width to make it smaller than the picture */
  height: 70%; /* Adjust height to match the picture height */
  background-color: rgba(255, 255, 255, 0.9); /* White square box with transparency */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px; /* Adjust padding as needed */
}

.overlay-overlay-box h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.overlay-overlay-box p {
  font-size: 16px;
  margin-bottom: 20px;
}

.overlay-fancy-text {
  font-family: 'Great Vibes', cursive;;
  font-size: 2em;
}

.overlay-best-text {
  font-family: 'Great Vibes', cursive;;
  font-size: 2em;
}

.overlay-first-header {
  font-size: 24px; /* Font size for the first header */
  font-family: 'YourFont'; /* Replace 'YourFont' with your desired font */
  font-weight: normal; /* Not bold */
}

.overlay-second-header {
  font-size: 18px; /* Font size for the second header */
  font-family: 'YourFont'; /* Replace 'YourFont' with your desired font */
  font-weight: bold; /* Bold */
}


.overlay-services-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  transition-duration: 0.4s;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.overlay-services-button:hover {
  background-color: #45a049; /* Darker Green */
}

.overlay-circles {
  position: absolute;
  bottom: 30px; /* Adjust vertical position as needed */
  width: 85%; /* Ensure full width */
  display: flex;
  justify-content: center; /* Center circles horizontally */
  align-items: center;
  margin-top: 10px;
}

.overlay-circles span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
  cursor: pointer;
}

.overlay-circles span.overlay-active {
  background-color: #d0b0b0;
}
