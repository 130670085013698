body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  position: relative;
}

.header {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.header-title h1 {
  font-size: 24px;
}

.header-title button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.menu {
  display: flex;
  align-items: center;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu ul li {
  margin-right: 20px;
  cursor: pointer;
}

.menu ul li:hover {
  text-decoration: underline;
}

.photo-section {
  margin-top: 80px; /* Adjust according to header height */
  text-align: center;
}

.photo-container {
  position: relative;
}

.photo-container img {
  width: 100%;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  z-index: 2;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
