
/* Custom styling for each paragraph */
.valued-services-section {
  /* background-color: gray; */
  background-color:  rgb(54, 47, 47);
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Adjust the height as needed */
}

.valued-services {
  text-align: center;
}

.valued-services-valued {
  font-family: 'Great Vibes', cursive;
  font-size: 2em;
}

.valued-services-services {
  color: black; /* Example styling for the first paragraph */
  font:400;
  font-weight: bold;
}

.valued-services-text {
  font-style: italic; /* Example styling for the second paragraph */
  font: 4px;
}

.valued-services-best {
  font-weight: 500; /* Adding the unit '500' refers to 'font-weight: 500;' */
  font-family: 'Cedarville Cursive';
  font-size: 140%;
  font-style: italic; 
  font-weight: bold;
}



