/* Footer container styles */
.footer {
    background-color: #000;
    color: #fff;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
  
  /* Styles for the left section of the footer */
  .footer-left {
    flex: 1; /* Take up remaining space */
    padding-left: 2.5%;
    padding-bottom: 2.5%;
    margin-bottom: 30px;
  }
  
  .company-logo {
    width: 50px; /* Adjust logo size as needed */
    margin-bottom: 10px;
  }
  
  .company-name {
    font-size: 18px; /* Adjust company name font size */
    /* font-weight: bold; */
    margin-bottom: 5px;
    font-family: 'Batang';
    font-weight: bolder;
  }
  
  .caption {
    font-size: 14px; /* Adjust caption font size */
    margin-bottom: 5px;
    font-family: 'Calibri';
  }
  
  .company-address,
  .company-contact {
    font-size: 14px; /* Adjust address and contact font size */
    margin-bottom: 5px;
    font-family: 'Calibri';
  }
  
  /* Styles for the middle section of the footer */
  .footer-middle {
    flex: 1; /* Take up remaining space */
    text-align: center; /* Center align content */
    margin-top: 20px; /* Add margin from top */
  }
  
  /* Styles for the right section of the footer */
  .footer-right {
    flex: 1; /* Take up remaining space */
    text-align: right; /* Right align content */
    padding-left: 30%;
    padding-top: 1%;
    margin-bottom: 30px;
  }
  
  .subscribe-section {
    text-align: right; /* Right align content */
  }
  
  .subscribe-text {
    font-size: 16px; /* Adjust subscribe text font size */
    padding-right: 100%;
    font-weight: bold;
  }
  
  .subscribe-info {
    font-size: 14px; /* Adjust subscribe info font size */
    margin-bottom: 10px;
    padding-right: 35%;
  }
  
  .subscribe-form {
    display: flex; /* Display form elements in a row */
  }
  
  .email-input {
    padding: 8px;
    border-radius: 5px;
    margin-right: 10px;
    border: none;
  }
  
  .signup-button {
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    color: #000;
    cursor: pointer;
  }
  
  /* Position copyright text at the bottom */
  .copyright {
    position: absolute;
    /* top: 20px; */
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 40px; /* Add margin from top */
    /* padding-top: 20%; */
  }
  