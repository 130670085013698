/* Custom styling for the section with background image */
.insight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Adjust the height as needed */
  position: relative;
}

/* Custom styling for the insight section */
.insight-section {
  text-align: center;
  font-family: 'Arial', sans-serif; /* Set a formal and good font */
}

.insight-section-client-text {
  font-family: 'Great Vibes', cursive;
  font-size: 2.5em;
}

.insight-section-collection-text {
  font-style: normal;
  font-size: 1.5em;
}

.insight-section-testimony-text {
  font-family: 'Homemade Apple';
  font-style: normal;
  font-size: 1.5em;
  line-height: 1.5; /* Adjust line height as needed */
  max-height: 6em; /* Adjust to fit 4 lines */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}

.insight-button {
  background-color: aquamarine;
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.insight-circles {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.insight-circles span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.insight-circles span.active {
  background-color: #333;
}

