/* Custom styling for the section with background image */
.collection {
  position: relative;
  background-image: url('./images/collection/msbpmc.jpg');
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.5); /* Lighter background color with some transparency */
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Adjust the height as needed */
}

/* Custom styling for each paragraph */
.collection-section {
  text-align: center;
  font-family: 'Arial', sans-serif; /* Set a formal and good font */
}

.collection-section-collection-text {
  font-size: 2em;
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: bold; /* Make text bold */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background to highlight text */
  padding: 7px; /* Add padding to enhance visibility */
  border-radius: 5px; /* Add border radius for a softer look */
  background-size: auto;
}

.collection-section-Dovetailing-text,
.collection-section-every-text {
  font-size: 140%;;
  margin-top: 2em; /* Adjust the space between lines here */
  font-weight: normal; /* Reset font weight to normal */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background to highlight text */
  padding: 10px; /* Add padding to enhance visibility */
  border-radius: 5px; /* Add border radius for a softer look */
  
}

.collection-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

