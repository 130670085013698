
/* Custom styling for each paragraph */
.fancy-text-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Adjust the height as needed */
  }
  
  .fancy-text {
    text-align: center;
  }

  .welcome {
    font-family: 'Great Vibes', cursive;;
    font-size: 2em;
  }
  
  .crafting-homes {
    color: black; /* Example styling for the first paragraph */
    font-family: 'Homemade Apple';
  }
  
  .discerning-clients {
    font-style: normal; /* Example styling for the second paragraph */
  }
  
  .since-1986 {
    font-weight: bold;
  }
  
  .passion-line {
    font-weight: 500; /* Adding the unit '500' refers to 'font-weight: 500;' */
    font-family: 'Cedarville Cursive';
    font-size: 120%;
  }

  .more-about-us-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    transition-duration: 0.4s;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .more-about-us-button:hover {
    background-color: #45a049; /* Darker Green */
  }
  